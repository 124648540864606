<template>
  <v-app class="my-application">
    <Sidepanel v-if="sidepanelDealsAdd" />
    <SidepanelProperty v-if="sidepanelPropertiesAdd" />
    <SidepanelDeveloper v-if="sidepanelDevelopmentsAdd" />
    <div
      v-if="invoiceSidepanelOpen || invoiceUserSidepanelOpen"
      id="sidepanels-wrapper"
      class="sidepanels-wrapper"
    >
      <InvoiceSidepanel v-if="invoiceSidepanelOpen" />
      <InvoiceBrokerSidepanel v-if="invoiceUserSidepanelOpen" />
    </div>
    <ContactAddModal
      v-if="sidePanelContactAdd"
      :not-contact-view-route="true"
      :isEdit="false"
      @closed="toggleContactAddPanel(0)"
      style="z-index: 150"
    />
    <BuildUpdateNotification @update="updateBuild" v-if="newVersionAvailable" />
    <WelcomeNotification />
    <div class="" style="display: flex; overflow-x: hidden">
      <NavBar
        v-if="$route.name && showRoutes"
        v-bind:class="{
          '': !newVersionAvailable,
          'top-64': newVersionAvailable,
        }"
        @expanded="handleNewSize('expanded')"
        @collapsed="handleNewSize('collapsed')"
      />
      <v-main
        class="main-wrapper"
        v-bind:class="{
          'main-wrapper': !newVersionAvailable,
          'main-wrapper': newVersionAvailable,
        }"
      >
        <TheTopbar
          v-if="$route.name && showRoutes"
          v-bind:class="{
            '': !newVersionAvailable,
            'top-88': newVersionAvailable,
          }"
          :style="{
            width: isExpanded ? 'calc(100% - 220px)' : 'calc(100% - 67px)',
          }"
        />
        <div
          class="main-view"
          :style="{
            width: isExpanded ? 'calc(100% - 220px)' : 'calc(100% - 67px)',
          }"
        >
          <router-view />
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import NavBar from "./components/Navbar.vue";
import TheTopbar from "./components/TheTopbar.vue";
import Sidepanel from "@/components/Deals/SidepanelAdd";
import SidepanelProperty from "@/components/PropertyOwner/AddPropertySidepanel";
import SidepanelDeveloper from "@/components/PropertyDeveloper/AddDevelopmentSidepanel";
import BuildUpdateNotification from "@/components/common/BuildUpdateNotification";
import WelcomeNotification from "@/components/common/WelcomeNotification";

const msalConfig = require("./configs/msalConfig");
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/et";
import "moment/locale/en-gb";
import axios from "axios";
import * as Msal from "@azure/msal-browser";
import ContactAddModal from "@/components/Kliendiregister/AddModal";
import InvoiceSidepanel from "@/components/Invoices/InvoiceSidepanel";
import InvoiceBrokerSidepanel from "@/components/Invoices/InvoiceBrokerSidepanel";

export default {
  name: "App",
  components: {
    InvoiceBrokerSidepanel,
    InvoiceSidepanel,
    NavBar,
    TheTopbar,
    Sidepanel,
    BuildUpdateNotification,
    WelcomeNotification,
    SidepanelProperty,
    SidepanelDeveloper,
    ContactAddModal,
  },
  data() {
    return {
      newVersionAvailable: false,
      dontShowNavTopBarRoutes: [
        "Login",
        "TheRegister",
        "RegisterSelfUser",
        "ResetPassword",
        "SelfServiceBoard",
        "LoginSelfService",
        "ListingsAdd",
        "ListingsChange",
        "DevelopmentListingChange",
        "ListingsAddToProject",
        "TheOffers",
        "ListingsFullDetail",
        "CompanyListingsLander",
        "SelfRegister",
      ],
      isExpanded: false,
    };
  },
  sockets: {
    connect: function () {
      // console.log(1);
    },
  },
  mounted() {
    this.checkForBuildUpdates();
    this.setupMicrosoft();
    this.setupLocale();
    this.checkStoreHasFilterLogout();
    if (!this.$route.params.id) {
      this.changeSidepanelStateDeals(0);
      this.changeClientAddModal(0);
      this.changeSidepanelStateProperties(0);
      this.changeSidepanelStateDevelopments(0);
    }
    this.connectSockets();
    if (this.user) {
      this.getCompanyPolicies();
    }
  },
  computed: {
    ...mapGetters([
      "sidepanelDealsAdd",
      "sidepanelPropertiesAdd",
      "sidepanelDevelopmentsAdd",
      "sidePanelContactAdd",
      "invoiceSidepanelOpen",
      "invoiceUserSidepanelOpen",
      "language",
      "languageSet",
      "buildVersion",
      "users",
      "user",
      "filters",
    ]),
    showRoutes: function () {
      return !this.dontShowNavTopBarRoutes.includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions([
      "logout",
      "setLanguage",
      "setLanguageSet",
      "changeSidepanelStateDeals",
      "changeClientAddModal",
      "changeSidepanelStateProperties",
      "changeSidepanelStateDevelopments",
      "setBuildVersion",
      "setMsalObject",
      "getCompanyPolicies",
      "getUsers",
      "setUser",
      "getUser",
      "resetFilters",
      "updateListings",
      "toggleContactAddPanel",
    ]),
    async handleNewSize(state) {
      this.isExpanded = state === "expanded";

      let requestBody = { navbarState: state };
      await axios.post(
        `/api/admin/update-navbar-state/${this.user._id}`,
        requestBody,
      );
    },
    async initData() {
      await this.getUsers();
    },
    async checkStoreHasFilterLogout() {
      await this.getUsers();
      if (
        !this.$store.state.d.filters.projects ||
        !this.$store.state.d.filters.projects[0].conditions.some(
          (e) => e.name === "balcony",
        )
      ) {
        this.resetFilters("projects");
      }
    },
    connectSockets() {
      if (this.sockets && typeof this.sockets.subscribe === "function") {
        this.sockets.subscribe(this.user._id, (data) => {
          console.log("socket: ", data);
          if (!data) {
            this.getCompanyPolicies();
          } else if (data.listingId) {
            this.updateListings();
          } else if (data.updateUserFields) {
            this.getUser({
              userId: this.user._id,
              arrayOfFieldKeys: data.updateUserFields,
            });
          }
        });
      } else {
        console.error(
          "Sockets are not initialized or subscribe method is not available.",
        );
      }
    },
    setupLocale() {
      this.setLanguageSet(Cookies.get("languageSet") === "true");

      if (!Cookies.get("language")) {
        let userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes("et")) {
          this.setLanguage("et");
          this.$i18n.locale = "et";
        } else {
          this.setLanguage("en");
          this.$i18n.locale = "en";
        }
      } else {
        this.setLanguage(Cookies.get("language"));
        this.$i18n.locale = Cookies.get("language");
      }
      this.$nextTick(() => {
        switch (this.language) {
          case "en":
            moment.locale("en-gb");
            break;
          case "et":
            moment.locale("et");
            break;
        }
      });
    },
    setupMicrosoft() {
      let msalObject = new Msal.PublicClientApplication(msalConfig.msalConfig);
      this.setMsalObject(msalObject);
    },
    updateBuild() {
      this.newVersionAvailable = false;
      window.location.reload();
    },
    async setInitialVersion() {
      const environment = window.location.host.split(".")[0];
      if (environment.includes("localhost")) return;

      const versionResponse = await axios.get(`/api/version/${environment}`);
      const liveVersion = versionResponse.data.dateChanged;
      await this.setBuildVersion(liveVersion);
    },
    async checkForBuildUpdates() {
      await this.setInitialVersion();

      const localVersion = this.buildVersion;
      const environment = window.location.host.split(".")[0];
      if (environment.includes("localhost")) return;

      const updateInterval = setInterval(async () => {
        const versionResponse = await axios.get(`/api/version/${environment}`);
        const liveVersion = versionResponse.data.dateChanged;
        if (localVersion !== liveVersion) {
          await this.setBuildVersion(liveVersion);
          clearInterval(updateInterval);
          this.newVersionAvailable = true;
        }
      }, 10000);
    },
    logOut() {
      this.logout();
      this.$router.push("/login");
      Cookies.remove("token");
    },
    haveToken() {
      return (
        document.cookie
          .match("(^|;)\\s*" + "token" + "\\s*=\\s*([^;]+)")
          ?.pop() ?? false
      );
    },
  },
  watch: {
    async "$route.name"() {
      if (this.showRoutes) await this.initData();
    },
  },
};
</script>

<style lang="scss">
/* Bold Weight */
@import "global_variables";
//@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Geomanist";
  src: url("assets/fonts/Geomanist-Medium.otf");
}

@font-face {
  font-family: "Geomanist-Light";
  src: url("assets/fonts/Geomanist-Light-Webfont/geomanist-light-webfont.ttf");
}

.tooltip-container {
  display: inline-block;
  position: relative;
}

.main-view {
  height: calc(100% - 60px);
}

.custom-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  z-index: 9999;
  white-space: nowrap;
  pointer-events: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateX(-10px);
  transition:
    opacity 200ms,
    transform 200ms;
}

.custom-tooltip.visible {
  opacity: 1;
  transform: translateX(0);
}

.main-wrapper {
  background-color: #f4f5f7;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mapdiv {
  border-radius: 8px;
}

:deep(.v-text-field--outlined) fieldset {
  border: 1px solid #e6e8ec;
}

.v-input__append-outer,
.v-input__prepend-outer {
  margin-top: 8px;
  margin-bottom: 8px;
}

// Align appended/preppended inner icons properly (can use flex)
.v-input__append-inner,
.v-input__prepend-inner :deep(*) {
  align-self: center;
  margin: 0;
  padding: 0;
}

.v-input__prepend-inner {
  margin-right: 8px;
}

.v-input__append-inner {
  margin-left: 8px;
}

body {
  background: #f4f5f7;
}

.my-application {
  width: 100vw;
  .img16 {
    width: 16px;
    height: 16px;
  }

  .img20 {
    width: 20px;
    height: 20px;
  }

  .img24 {
    width: 24px;
    height: 24px;
  }

  .gaps10px {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }

  #textarea {
    border: 1px solid $grey500;
    padding: 12px;
    border-radius: 8px;
    width: 100%;

    &:focus {
      outline: none !important;
      border: 1px solid $grey500 !important;
    }
  }

  .v-input {
    margin-top: 0 !important;
    border-radius: 8px !important;

    ::placeholder {
      color: $grey950 !important;
      font-family: Inter, sans-serif !important;
    }
  }

  .theme--light.v-label {
    color: $grey950 !important;
    //letter-spacing: 0.05em !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
      .v-input--has-state
    )
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $grey500 !important;
  }

  .theme--light.v-text-field--outlined
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $grey500 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-family: Geomanist, sans-serif;
    color: rgba(0, 0, 0, 1) !important;
  }

  h1 {
    font-weight: 500;
  }

  h2 {
    font-family: Geomanist, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 24px;
  }

  h3 {
    font-family: Geomanist, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 24px;
  }

  h4 {
    font-family: Geomanist, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
  }

  h5 {
    font-family: Geomanist, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
  }

  h6 {
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0px !important;
    font-family: "Inter", sans-serif;
    color: rgba(0, 0, 0, 1);
  }

  .heading-geo32 {
    font-family: "Geomanist-Light", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $grey950;
  }

  .link-small {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    cursor: pointer;
    /* black */

    color: #000000;
  }

  .font-11-regular {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #000000;
  }

  .font-16-regular {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .font-16-bold {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .font-20-medium {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
  .font-20-medium-black {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .font-32-light {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
  }

  .font-18-bold {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  .font-12-CAPS {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .font-10-CAPS {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
  }

  .geo-32-light {
    font-family: "Geomanist-Light", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: $grey950;
  }

  .subheading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $grey950;
  }

  .content-small {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    text-wrap: nowrap;
  }
  .content-small-gray {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(117, 120, 122, 1);
  }
  .content-small-orange {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(255, 92, 1, 1);
  }
  .content-small-medium {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .content-small-semibold {
    /* content/content small semibold */
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */

    color: #000000;
  }
  .content-small-bold {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
  }

  .content-bold {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .content-normal {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
  }

  .content {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
  }

  .content-medium {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .content-semibold {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1) !important;
  }

  .content-bold {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .button-small {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
  .label10 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #ffffff;
  }
  .label12 {
    /* label 12 */

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    /* black */

    color: #000000;
  }

  .label11 {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0.05em;

    color: #000000;
  }

  .label10 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }

  .semi-bold {
    font-weight: 600;
  }

  .link {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* black */

    color: #000000;
  }

  .v-list-item--link:before {
    background-color: $grey;
  }

  .v-list-item--active {
    background-color: $grey;
  }

  .v-list--nav .v-list-item,
  .v-list--nav .v-list-item:before {
    border-radius: 8px;
  }

  .green-background {
    background-color: $green;
  }

  .no-padding {
    padding: 0 !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  button {
    border-radius: 8px;
  }

  .flex {
    display: flex;
  }

  .justify-right {
    justify-content: flex-end;
  }

  .row {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .row + .row {
    margin-top: 12px;
  }

  .v-input__slot {
  }

  .theme--light.v-text-field--outline:not(.v-input--is-focused):not(
      .v-input--has-state
    )
    > .v-input__control
    > .v-input__slot:hover {
    border-width: 1px;
    border-style: solid;
    border-color: #6fbd44;
  }

  .theme--light.v-text-field--outline.v-input--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #6fbd44;
  }

  .v-text-field__slot {
    margin-right: 10px;
  }

  .v-text-field__slot input {
    color: black !important;
    caret-color: black !important;
    font-family: Inter, sans-serif !important;
  }

  .add-button {
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    border: none;
    padding: 12px 24px;
    background: #000000;
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .transparent-button {
    display: inline-flex;
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    cursor: pointer;
    padding: 12px 16px;
    line-height: 16px;
    border-radius: 8px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    gap: 8px;
  }

  .text-black,
  .text-orange {
    color: #ff5c01 !important;
  }

  .add-new-button,
  .filter-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 12px 16px;
    background: #000000;
    border-radius: 8px;
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .title-row {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 44px;
    padding-right: 24px;
    height: 32px;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  :deep(.dp__main) {
    width: auto;
    padding: 0px;
  }

  :deep(.dp__input) {
    border-radius: 8px !important;
  }

  .dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #ff5c01;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
  }

  .toolbar-row {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 20px 104px 20px 40px;
    z-index: 3;

    .point-separator {
      margin-left: 8px;
      margin-right: 8px;
      height: 4px;
      width: 4px;
    }
  }

  .search-button {
    width: 260px;
    margin-left: 8px;

    &:hover {
      background: transparent;
    }
  }

  .cancel {
    background-color: transparent;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px 24px;
    height: 40px;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    border-radius: 8px;
    align-items: center;
    display: flex;
    color: rgba(0, 0, 0, 1);
    font-family: "Inter", sans-serif;
  }

  .delete {
    background-color: #ff1e24;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px 24px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    align-items: center;
    display: flex;
    color: white;
  }

  .v-input__prepend-inner {
    padding-right: 8px !important;
  }

  .gray-text {
    color: $grey950;
  }

  .gray {
    background: #f4f5f7;
  }

  .green {
    background: #0aaf60;
    color: white;
  }

  .orange {
    background: #ff5c01;
    color: white;
  }

  .hover-option {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  button.main-small-secondary {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid $grey500;
    border-radius: 8px;
    padding: 0 12px;
  }

  .blurred {
    filter: blur(6px);
  }
}

.outlined-small-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid #e6e8ec;
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 1) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    background: #e6e8ec;
  }
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  margin-right: 4px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */
  max-width: fit-content;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}
.clear-filters-button,
.add-widget-button {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;

  color: #75787a !important;

  margin: 13px 0px;
}
.grey900 {
  color: $grey900 !important;
}

.light-grey {
  background-color: $light-grey;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #a0a0a0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
.loader-bar,
.loader-bar:before,
.loader-bar:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader-bar {
  color: rgba(223, 220, 220, 0.74);
  font-size: 6px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader-bar:before,
.loader-bar:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader-bar:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-bar:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100vh;
  background: white;
  z-index: 2;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

.field-label {
  height: 12px;
  display: flex;
  align-items: center;
  //margin-bottom: 4px !important;
}

.field-label-text {
  letter-spacing: 0.05em;
  //text-transform: capitalize;
  color: $grey950 !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
}

.draft-label {
  background: #75787a;
}

.invited-label {
  background: #4c8dd9;
}

.inactive-label {
  background: #c3c4c6;
}

.active-label {
  background: #0aaf60;
}

.booked-label {
  background: #ff5c01;
}

.sold-label {
  background: #ff1e24;
}

.archived-label {
  background: #c3c4c6;
}

.rented-label {
  background: #ff1e24;
}

.expired-label {
  background: #c3c4c6;
}

.required label::after,
.required h6::after,
.required p::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.error-text {
  color: $red;
}

//data-number-small
.font-20-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.font-32-extrabold {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
}

.pac-container {
  border-radius: 8px !important;
  border: 0 !important;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04) !important;
}

.pac-icon {
  display: none !important;
}

.pac-item {
  border: 0 !important;
  padding: 12px !important;
  background-color: white !important;
  cursor: pointer !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: black !important;
}

.pac-item:hover {
  background: #f4f5f7 !important;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.top-88 {
  margin-top: 88px !important;
}

.top-64 {
  margin-top: 64px !important;
}

.top-24 {
  margin-top: 24px !important;
}
.space-between {
  justify-content: space-between;
}
.pd-bottom-32 {
  padding-bottom: 32px;
}

.sidepanels-wrapper {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 35;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 200px;
  height: 34px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}

.focus-container {
  position: relative;
  max-width: 200px;
  height: 32px;
  border: 1px solid white;
  border-radius: 9px;
  display: flex;
  align-items: center;
}

.focus-container.focused {
  border-color: #e6e8ec;
}

.search-input {
  max-width: 200px;
  height: 100%;
  padding-left: 36px;
  box-sizing: border-box;
  font-size: 14px;
  border: none;
  outline: none;
}

.search-input::placeholder {
  color: rgba(0, 0, 0, 0.47);
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: text;
}
</style>
