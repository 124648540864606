<template>
  <div
    v-if="user"
    v-bind:class="{
      'top-row': $route.name !== 'DevelopmentGeneral',
      'top-row padding-left-96': $route.name === 'DevelopmentGeneral',
    }"
    class="top-navbar top-row-default"
  >
    <div class="left-top-row">
      <div v-if="showBackButton" @click="updateBackButton" class="back-button">
        <img
          height="24"
          width="24"
          :src="require('@/assets/images/chevron-left.svg')"
          alt=""
        />
        <p>{{ $t("Back") }}</p>
      </div>
    </div>
    <div class="right-top-row">
      <button
        name="send-offers-button"
        class="transparent-button"
        style="margin-right: 40px"
        @click="openOffersSidepanel"
      >
        <img :src="require('@/assets/images/ads_20_20.svg')" alt="" />
        <div class="content-small">
          {{ $t("SendOffer") }}
        </div>
        <div
          :class="{ 'offers-amount-red': offersLeadsCount > 0 }"
          class="offers-amount content-small"
        >
          {{ offersLeadsCount }}
        </div>
      </button>

      <TheNotifications v-if="user" style="margin-right: 12px" />

      <TheInfo style="margin-right: 40px" />

      <!--  <LanguageToggle style="margin-right: 40px" /> -->

      <UserSettingsButtonDropdown :current-user="user" />

      <div>
        <OffersSidepanel
          v-if="offersSidepanelOpen"
          @closePanel="offersSidepanelOpen = false"
          @openClientPanel="openClientFromOffers"
        />
        <ClientSidepanel
          v-if="clientSidepanelOpen"
          @funnelRemoved="getListings()"
          @panelClosed="clientSidepanelOpen = false"
          :customer-data="offersCustomerId"
          :only-panel="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import axios from "axios";
import TheNotifications from "@/components/TheNotifications";
import OffersSidepanel from "@/components/Listings/OffersSidepanel";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel";
//import LanguageToggle from "@/components/common/LanguageToggle";
import TheInfo from "@/components/common/TheInfo";
import UserSettingsButtonDropdown from "@/components/common/UserSettingsButtonDropdown";

export default {
  name: "TheTopbar",
  components: {
    TheInfo,
    TheNotifications,
    OffersSidepanel,
    ClientSidepanel,
    //LanguageToggle,
    UserSettingsButtonDropdown,
  },
  data: () => ({
    username: "",
    account: null,
    calendarEvents: null,
    offersAmount: 0,
    offersSidepanelOpen: false,
    clientSidepanelOpen: false,
    offersCustomerId: null,
  }),
  created() {
    this.countOffers();
  },
  computed: {
    ...mapGetters([
      "user",
      "offersLeadsCount",
      "backButtonUpdater",
      "showBackButton",
    ]),
    showLogout() {
      return (
        document.cookie
          .match("(^|;)\\s*" + "token" + "\\s*=\\s*([^;]+)")
          ?.pop() ?? false
      );
    },
  },
  watch: {
    offersLeadsCount() {
      this.countOffers();
    },
  },
  methods: {
    ...mapActions([
      "getListings",
      "logout",
      "setOffersLeadsIdsWithDuplicates",
      "updateBackButton",
    ]),
    openClientFromOffers(customerId) {
      this.clientSidepanelOpen = true;
      this.offersCustomerId = customerId;
    },
    openOffersSidepanel() {
      this.offersSidepanelOpen = true;
    },
    async countOffers() {
      const response = await axios.get(`/api/listings/offers`);
      let listings = response.data;
      listings = listings.filter((listing) => {
        if (listing.funnels.Offers) {
          return listing.funnels.Offers.length > 0;
        }
      });
      const offerObjects = [];
      const customerIds = [];
      listings.forEach((offer) => {
        offer.funnels.Offers.forEach((funnel) => {
          if (offerObjects.find((object) => object.leadId === funnel.leadId)) {
            offerObjects.forEach((offerObject, index) => {
              if (
                offerObject.leadId === funnel.leadId &&
                funnel.statusCode !== 0
              ) {
                offerObjects[index]["listings"].push({
                  id: funnel.listingId,
                  name: offer.listingName,
                });
              }
            });
          } else if (funnel.statusCode !== 0) {
            const offerObject = {};
            customerIds.push(funnel.leadId);
            offerObject["leadId"] = funnel.leadId;
            offerObject["name"] = funnel.leadName;
            offerObject["listings"] = [];
            offerObject["listings"].push({
              id: funnel.listingId,
              name: offer.listingName,
            });
            offerObject["dropdownOpen"] = false;
            offerObject["selected"] = false;
            offerObjects.push(offerObject);
          }
        });
      });
      this.items = customerIds;
      await this.setOffersLeadsIdsWithDuplicates(this.items);
    },
    logOut() {
      this.logout();
      this.$router.push("/login");
      Cookies.remove("token");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.top-row-default {
  background-color: #ffffff;
  width: 100vw;
}

.offers-amount {
  background-color: #75787a;
  border-radius: 4px;
  font-weight: bold;
  color: white !important;
  padding: 0 4px;
}

.offers-amount-red {
  background-color: $red !important;
}

.transparent-button {
  padding: 8px 12px !important;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 220px;
  padding-right: 40px;
  align-items: center;
  height: 60px !important;
  max-height: 60px !important;
}
.padding-left-96 {
  padding-left: 96px !important;
}
.v-btn {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
}

.no-outline {
  box-shadow: none;
}

.sync-outlook {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;

  &:hover {
    background: #e7e7e7;

    .remove-outlook {
      display: flex !important;
    }
  }
}

.remove-outlook {
  display: none !important;
  align-self: flex-end;
  text-decoration: underline;
}

.sync-outlook-text {
  cursor: default;
}

.not-synced {
  cursor: pointer;
}

.data-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.back-button {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  align-content: center;
  cursor: pointer;
}

.back-button p {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 16px;
  display: flex;
  align-self: center;
}

.left-top-row {
  display: flex;
}

.right-top-row {
  display: flex;
  align-content: center;
  align-items: center;
}
</style>
